
import React, { useEffect, useState } from "react";
import logo from "../assets/images/gameofyy.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const initialMsisdn = searchParams.get("msisdn");
  
  const [msisdn, setMsisdn] = useState(initialMsisdn || "");
  const [subscriptionType, setSubscriptionType] = useState("Daily");
  const navigate = useNavigate();

  // Check cookies (you can evaluate this as required)
  // useEffect(() => {
  //   const cookies = document.cookie.split("; ");
  //   const gameofyyCookie = cookies.find((cookie) => cookie === "gameofyy=1");
  //   if (gameofyyCookie) {
  //     window.location.href = "/home";
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let pack = 'GAME'
    if (subscriptionType === '3Day') {
      pack = 'GAME3'
    }
    if (subscriptionType === 'Weekly') {
      pack = 'GAME7'
    }

    const formattedMsisdn = msisdn.startsWith('229') ? msisdn : `229${msisdn}`;

    axios
      .get(`/websub?ani=${formattedMsisdn}&msg=${pack}`)
      .then((response) => {
       
        console.log("RESPONSE FROM SUBSCRIPTION MO",response.data)
        if (response.data == "Already Subscribed") {
          setLoading(false);
          Cookies.set('msisdn', formattedMsisdn)
          toast.success("You are already subscribed")
        
          setTimeout(() => {
            navigate(`/home?msisdn=${formattedMsisdn}`);
          }, 2000);
        }
        if (response.data === "Success") {
          let data = {
            msisdn: formattedMsisdn,
            service: "gameofyy"
          };
          const loginurl = '/loginuser';
    
          setTimeout(() => {
            axios.post(loginurl, data)
              .then(loginResponse => {
                if (loginResponse.data.status =="1") {
                  setLoading(false);
                  Cookies.set('msisdn', formattedMsisdn);
                  setTimeout(() => {
                    navigate(`/home?msisdn=${formattedMsisdn}`);
                  }, 2000);
                } else {
                  setLoading(false);
                  setMsisdn('')
                  toast.error("Oops something went wrong");
                }
              })
              .catch(() => {
                setLoading(false);
                console.log("error")
                // toast.error("Oops something went wrong");
              })
          }, 10000);
        } else {
          setMsisdn('')
          // toast.error("Oops something went wrong");
        }
      })
      .catch((error) => {
        console.log("error catch",error)
        setMsisdn('')
        setLoading(false);
      
      });
  };

  const getSuccessMessage = () => {
    switch (subscriptionType) {
      case "Daily":
        return " Cher abonné, vous avez souscris avec succès à 50F valable 24h.";
      case "3Day":
        return "Cher abonné, vous avez souscris avec succès à 100F valable 3 jours.";
      case "Weekly":
        return "Cher abonné, vous avez souscris avec succès à 150F valable 7 jours.";
      default:
        return "You have successfully subscribed.";
    }
  };

  return (
    <div className="bg-[#623193] h-screen">
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-300"></div>
        </div>
      )}

      {errorModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-3xl mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">Error</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setErrorModalOpen(false)}
                >
                  <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                  {errorMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {successModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-md mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">Success!</h3>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                 {getSuccessMessage()}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto flex flex-col justify-center py-[60px]">
        <div className="mx-auto">
          <img className="rounded-t-lg h-20 w-20" src={logo} alt="" />
        </div>
        <div className="flex justify-center py-3">
          <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="p-5 shadow-xl shadow-purple-600 ">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <h5 className="text-2xl text-[#6B57EF] font-bold text-center capitalize dark:text-white">
                  Souscrivez et Profitez!
                </h5>
                {initialMsisdn ? (
                  <div>
                    <label
                      htmlFor="number"
                      className="block mb-2 text-sm font-medium text-gray-700 dark:text-grey-700"
                    >
                      MSISDN
                    </label>
                    <input
                      type="text"
                      name="number"
                      id="number"
                      value={msisdn}
                      readOnly
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                ) 
                : (
                  <div>
                    <label
                      htmlFor="number"
                      className="block mb-2 text-sm font-medium text-gray-700 dark:text-grey-700"
                    >
                      Veuillez entrer votre numéro
                    </label>
                    <input
                      type="number"
                      name="number"
                      id="number"
                      value={msisdn}
                      onChange={(e) => setMsisdn(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="*********"
                      required
                    />
                  </div>
                )}

<div>
            <label htmlFor="subscriptionType" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-700">
              Type d'abonnement
            </label>
            <select
              id="subscriptionType"
              value={subscriptionType}
              onChange={(e) => setSubscriptionType(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
            >
             <option value="Daily">Jour :Souscrivez à partir de 50F</option>
              <option value="3Day">3 jours : Souscrivez à 100F</option>
              <option value="Weekly">Hebdo :Souscrivez à partir de 150F</option>
            </select>
          </div>

                <button
                  type="submit"
                  className="w-full text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                  Souscrivez maintenant
                </button>
              </form>
              {!initialMsisdn && (
                <p className="text-sm text-gray-700 dark:text-grey-700 text-center mt-2">
                  Veuillez svp utiliser votre connexion mobile pour une
                  meilleure expérience
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;

