import React from "react";
import logo from "../assets/images/gameofyy.png";

const ExistingUserNotcharged = () => {
  return (
    <div className="bg-[#623193] h-screen flex justify-center items-center">
      <div className="text-center">
        <img src={logo} alt="Game Logo" className="mx-auto h-20 w-20 mb-4" />
        <p className="text-white font-bold text-xl">
          {/* You are not charged for this service. */}
          Votre crédit est insuffisant, veuillez recharger svp et reessayer.
        </p>
      </div>
    </div>
  );
};

export default ExistingUserNotcharged;
