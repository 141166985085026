import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGames } from './store/Slice';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import Spinner from './Spinner';

function GameDetails() {
  const { gameid } = useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const msisdn=Cookies.get('msisdn')
  console.log("cookies in game", msisdn)
  const game = useSelector((state) =>
    state.game.data.games.find((game) => game.gameid === gameid)
  );


  useEffect(() => {
    // Fetch games when the component mounts
    dispatch(fetchGames());
  }, [dispatch]);

  if (!game) {
    // return <p>Game not found</p>;
    return(
      <Spinner/>
    )
  }

  const handleclick=()=>{
   navigate(`/home?msisdn=${msisdn}`) 
  }

  const ensureHttps = url => {
    if (url && url.startsWith("http://")) {
      return url.replace("http://", "https://");
    }
    return url;
  };
  
  return ( 
    <div className="bg-black h-screen relative">
      <Link to={`/home?msisdn=${msisdn}`} className='absolute top-4 p-2 text-red-600'>
        <IoMdClose size={30} onClick={()=>handleclick()} className='hover:cursor-pointer' />
      </Link>

      <div className='flex justify-center items-center bg-black h-screen'>
        <iframe 
          className='w-full h-screen p-2'
          title={game.gamename}
          src={ensureHttps(game.gameurl)}
        ></iframe>
      </div>
    </div>
  );
}

export default GameDetails;
