import React, { useEffect, useState } from "react";
import logo from "../assets/images/gameofyy.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const  HeaderLogin = () => {
  const [loading, setLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check for cookies or other login validations if needed
    // Redirect if already logged in or valid session/cookie is found
  }, []); // Dependency array is empty to run only on mount

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);

    // navigate("http://play.gameofyy.com/");
    window.location.href = "http://play.gameofyy.com/check-msisdn/";

    // try {
    //   const response = await axios.post("/subscribe/user", {
    //     mact: "web",
    //     service_name: "gameofyy",
    //   });
    //   if (response.data === "Success") {
    //     document.cookie = "gameofyy=1; path=/";
    //     navigate(`/home`);
    //   } else {
    //     setErrorModalOpen(true);
    //     setErrorMessage(response.data);
    //   }
    // } catch (error) {
    //   console.error("Subscription Error:", error);
    //   setErrorModalOpen(true);
    //   setErrorMessage(error.response.data);
    //   navigate("/home/login");
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <div className="bg-[#623193] h-screen">
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-300"></div>
        </div>
      )}

      {errorModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-3xl mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">Error</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setErrorModalOpen(false)}
                >
                  <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                  {errorMessage}
                </p>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={() => setErrorModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto flex flex-col justify-center py-[90px]">
        <div className="mx-auto">
          <img className="rounded-t-lg h-20" src={logo} alt="Game Logo" />
        </div>
        <div className="flex justify-center py-3">
          <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="p-5 shadow-xl shadow-purple-600 ">
              <h5 className="text-2xl text-[#6B57EF] font-bold text-center capitalize dark:text-white mb-4">
                {/* Subscribe at 100 CFA daily */}
                Souscrivez en tapant *717# à partir de 50F
              </h5>
              <form className="space-y-10" onSubmit={handleSubmit}>
                <button
                  type="submit"
                  className="w-full text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                
                  Souscrivez maintenant
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLogin;
