import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CategoryPage from "./components/CategoryPage";
import HomePage from "./components/HomePage";
import GameDetails from "./components/GameDetails";
import SearchBox from "./components/SearchBox";
import Login from "./components/Login";
import LoginHeader from "./components/LoginHeader";
import ExistingUserNotcharged from "./components/ExistingUserNotCharged";
import Userlogin from "./components/Userlogin";
import HeaderLogin from "./components/HeaderLogin";


function App() {
  // Function to check if the "gameoffy" cookie exists
  const checkCookie = () => {
    // Replace this with your logic to check the existence of the "gameoffy" cookie
    const gameoffyCookie = document.cookie.includes("gameofyy");
    return gameoffyCookie;
  };

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/home"
            element={
              <HomePage /> 
            }
          />
          <Route path="/home/login" element={<Login />} />
          <Route
            path="/home/not-charged"
            element={<ExistingUserNotcharged />}
          />
                <Route path="/home/check" element={<Userlogin/>} />
          <Route path="/home/login-header" element={<LoginHeader />} />
          <Route path="/home/header" element={<HeaderLogin />} />
          <Route
            path="/home/games/:gameid"
            element={
            <GameDetails />
            }
          />
          <Route
            path="/home/category/:categoryName"
            element={
              <CategoryPage />
            }
          />

      
          <Route
            path="/home/search"
            element={
              <SearchBox /> 
            }
          />

        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
